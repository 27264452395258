import { Button, Grid, Paper, TextField } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { fieldInitialValues } from "../../config/fieldIntialValues";
import MapAddUpdateFieldComponent from "../map/MapAddUpdateField.component";
import ActivitiesFertilizerComponent from "./ActivitiesFertilizer.component";
import ActivitiesIrrigationComponent from "./ActivitiesIrrigation.component";
import ActivitiesPesticideComponent from "./ActivitiesPesticide.component";
import ActivitiesSoilPreparationComponent from "./ActivitiesSoilPreparation.component";
import CropFormComponent from "./CropForm.component";
import IrrigationFormComponent from "./IrrigationForm.component";
import SensorsFormComponent from "./SensorsForm.component";
import SoilTypeFormComponent from "./SoilTypeForm.component";

const FieldFormComponent = (props) => {
  const { t } = useTranslation();
  const fieldSelected = useSelector((state) => state.field.data);
  const field = useSelector((state) => state.fields.data).find(
    (f) => f.id === fieldSelected
  );

  const [isWaterCounterPresent, setIsWaterCounterPresent] = useState(false);

  useEffect(() => {
    if (field && field.sensors && Array.isArray(field.sensors)) {
      const hasWaterCounter = field.sensors.some(
        (sensor) => sensor.type === "water_counter"
      );
      setIsWaterCounterPresent(hasWaterCounter);
    }
  }, [field]);

  const initialValues = props.addNew ? fieldInitialValues : field;

  const validationSchema = Yup.object({});

  return (
    <Fragment>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          {
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={props.onSubmit}
            >
              {(formikProps) => (
                <Form>
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12}>
                      <div>
                        <h3>{t("field_form.title")}</h3>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper
                        variant="outlined"
                        sx={{ height: "100%", padding: "20px" }}
                      >
                        <Grid container spacing={2} direction="column">
                          <Grid item xs={12} sm={12} md={4}>
                            <Field
                              as={TextField}
                              label={t("field_form.field_name")}
                              name="name"
                              variant="outlined"
                            />
                            <ErrorMessage name="name" component="div" />
                          </Grid>
                          <Grid item xs={12} sm={12} md={8}>
                            <MapAddUpdateFieldComponent
                              formikProps={formikProps}
                            ></MapAddUpdateFieldComponent>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    {!props.addNew && (
                      <Grid item xs={12}>
                        <div>
                          <h3>{t("activities")}</h3>
                        </div>
                        <Grid container spacing={2}>
                          {!isWaterCounterPresent && (
                            <Grid item xs={12} sm={12} md={6}>
                              <Paper
                                variant="outlined"
                                sx={{
                                  height: "100%",
                                  paddingX: "20px",
                                  paddingBottom: "20px",
                                }}
                              >
                                <ActivitiesIrrigationComponent
                                  formikProps={formikProps}
                                ></ActivitiesIrrigationComponent>
                              </Paper>
                            </Grid>
                          )}

                          <Grid item xs={12} sm={12} md={6}>
                            <Paper
                              variant="outlined"
                              sx={{
                                height: "100%",
                                paddingX: "20px",
                                paddingBottom: "20px",
                              }}
                            >
                              <ActivitiesSoilPreparationComponent
                                formikProps={formikProps}
                              ></ActivitiesSoilPreparationComponent>
                            </Paper>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <Paper
                              variant="outlined"
                              sx={{
                                height: "100%",
                                paddingX: "20px",
                                paddingBottom: "20px",
                              }}
                            >
                              <ActivitiesFertilizerComponent
                                formikProps={formikProps}
                              ></ActivitiesFertilizerComponent>
                            </Paper>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <Paper
                              variant="outlined"
                              sx={{
                                height: "100%",
                                paddingX: "20px",
                                paddingBottom: "20px",
                              }}
                            >
                              <ActivitiesPesticideComponent
                                formikProps={formikProps}
                              ></ActivitiesPesticideComponent>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Paper
                        variant="outlined"
                        sx={{
                          height: "100%",
                          paddingX: "20px",
                          paddingBottom: "20px",
                        }}
                      >
                        <IrrigationFormComponent
                          formikProps={formikProps}
                        ></IrrigationFormComponent>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper
                        variant="outlined"
                        sx={{
                          height: "100%",
                          paddingX: "20px",
                          paddingBottom: "20px",
                        }}
                      >
                        <SoilTypeFormComponent
                          formikProps={formikProps}
                        ></SoilTypeFormComponent>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper
                        variant="outlined"
                        sx={{
                          height: "100%",
                          paddingX: "20px",
                          paddingBottom: "20px",
                        }}
                      >
                        <CropFormComponent
                          formikProps={formikProps}
                        ></CropFormComponent>
                      </Paper>
                    </Grid>
                    {!props.addNew && (
                      <Grid item xs={12}>
                        <div>
                          <h3>{t("sensor")}</h3>
                        </div>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Paper
                              variant="outlined"
                              sx={{
                                height: "100%",
                                paddingX: "20px",
                                paddingBottom: "10px",
                              }}
                            >
                              <SensorsFormComponent
                                formikProps={formikProps}
                              ></SensorsFormComponent>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                          position: "fixed",
                          bottom: "50px",
                          left: "55%",
                          transform: "translateX(-50%)",
                          zIndex: 1000,
                          boxShadow: 10,
                        }}
                      >
                        {props.addNew ? t("add_field") : t("update_field")}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          }
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default FieldFormComponent;
