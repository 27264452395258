import L from "leaflet";
import "leaflet/dist/leaflet.css";

const createCustomIcon = (props) => {
  const markerHtmlStyles = `
  background-color: ${props.color ?? "blue"};
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -1rem;
  top: -1rem;
  position: relative;
  border-radius: 3rem 3rem 0;
  border: 1px solid #FFFFFF;
  transform: rotate(45deg);
  `;
  const markerTextStyles = `
  color: ${props.numberColor ?? "white"};
  font-weight: bold;
  transform: rotate(-45deg);
  `;

  return L.divIcon({
    className: "my-custom-pin",
    iconAnchor: [0, 24],
    labelAnchor: [-6, 0],
    popupAnchor: [0, -36],
    html: `<div style="${markerHtmlStyles}"><span style="${markerTextStyles}">${props.number ?? ''}</span></div>`,
  });
};

export default createCustomIcon;
