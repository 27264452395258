export const fieldInitialValues = {
  id: null,
  name: "",
  polygons: [
    { id: null, position: [], color: "blue", popup: null, tooltip: null },
  ],
  irrigations_system: {
    system: ""
  },
  soil_type: {
    clay: "",
    silt: "",
    sand: "",
    field_capacity: "",
    permanent_wilting_point: "",
    bulk_density: "",
    taw: "",
    raw: ""
  },
  crop: {
    type: "",
    species: "",
    variety_name: "",
    variety_code: "",
    distance_line: "",
    distance_individuals: "",
    planting_date: "",
    flowering_date: "",
    rieping_date: "",
    harvest_date: "",
    crop_yelds: "",
  },
  sensors: [],
  activities: {
    irrigation: [],
    soil_preparation: [],
    fertilizer: [],
    pesticide: [],
  },
  realtime: {
    irrigation_profile: {},
    transpiration_profile: {},
    suggested_irrigation: {},
    sensors_data: [],
  },
};

export const irrigationSystem = {
  sprinkler: "sprinkler",
  drip: "drip",
  suterranean: "suterranean",
};

export const soilType = {
  texture: "texture",
  field_capacity: "field_capacity",
  permanent_wilting_point: "permanent_wilting_point",
  bulk_density: "bulk_density",
  taw: "taw",
  raw: "raw",
};

export const cropType = {
  annual: "annual",
  perennial: "perennial",
};

export const speciesType = {
  tomato: "tomato",
  maize: "maize",
  potato: "potato",
  orange: "orange",
  onions: "onions",
};


export const cropAnnualKeys = [
  "type",
  "species",
  "variety_name",
  "variety_code",
  "distance_line",
  "distance_individuals",
  "planting_date",
  "flowering_date",
  "rieping_date",
  "harvest_date",
  "crop_yelds",
];

export const cropPerennialKeys = [
  "type",
  "species",
  "variety_name",
  "variety_code",
  "distance_line",
  "distance_individuals",
  "planting_year",
  "flowering_date",
  "rieping_date",
  "harvest_date",
  "crop_yelds",
];


export const soilTypeKeys = [
  "clay",
  "silt",
  "sand",
  "field_capacity",
  "permanent_wilting_point",
  "bulk_density",
  "taw",
  "raw"
];

export const irrigationsSystemKeys = [
  "system",
];

export const irrigationsSystemSprinklerKeys = [
  "system",
  "number",
  "amount"
];

export const irrigationsSystemDriprKeys = [
  "system",
  "number_driping_pipes",
  "space_driping_bores",
  "water_dispensed_bore",
  "wetted_area"
];

export const irrigationsSystemSuterraneanKeys = [
  "system",
  "depth_pipes",
  "number_driping_pipes",
  "amount",
  "water_dispensed_bore",
  "wetted_area"
];