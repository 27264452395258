import axios from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import { API_FERTILIZERS } from "../../config/api";
import { fetchActivityRequest } from "../../store/actions/activities.actions";
import { ADD_FERTILIZER, addFertilizerFailure, addFertilizerSuccess, DELETE_FERTILIZER, deleteFertilizerSuccess, deleteFertilizerFailure } from "../../store/actions/fertilizers.actions";
import { fetchFieldsDataRequest } from "../../store/actions/fields.actions";
import { fetchOveviewData } from "../../store/actions/oveview.actions";

const addFertilizer = async (token, activity) => {
  const fieldsUrl = `${API_FERTILIZERS}`;
  const data = activity;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.post(fieldsUrl, { data }, { headers });
  return response.data;
};

function* handleAddFertilizer(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const farmId = yield select((state) => state.farm.data.id);
    const activity = action?.payload;
    const response = yield call(addFertilizer, token, activity);
    yield put(addFertilizerSuccess(response));
    yield put(fetchActivityRequest(activity.activity));
    yield put(fetchFieldsDataRequest(farmId));
    yield put(fetchOveviewData());
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({
        type: alertInfoTypes.ERROR,
        message: error?.response?.data?.error?.message ?? error.message,
      })
    );
    yield put(addFertilizerFailure(error.message));
    yield put(hideProgress());
  }
}

export function* watchAddFertilizer() {
  yield takeLatest(ADD_FERTILIZER, handleAddFertilizer);
}


const deleteFertilizer = async (token, id) => {
  const url = `${API_FERTILIZERS}/${id}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.delete(url, { headers });
  return response.data;
};

function* handleDeleteFertilizer(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const farmId = yield select((state) => state.farm.data.id);
    const activityFertilizer = action?.payload.id;
    const response = yield call(deleteFertilizer, token, activityFertilizer);
    yield put(deleteFertilizerSuccess(response));
    yield put(fetchFieldsDataRequest(farmId));
    yield put(fetchActivityRequest(action?.payload.activity));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({
        type: alertInfoTypes.ERROR,
        message: error?.response?.data?.error?.message ?? error.message,
      })
    );
    yield put(deleteFertilizerFailure(error.message));
    yield put(hideProgress());
  }
}

export function* watchDeleteFertilizer() {
  yield takeLatest(DELETE_FERTILIZER, handleDeleteFertilizer);
}