import { Button, Grid, Icon, Typography, useMediaQuery } from "@mui/material";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FieldFormComponent from "../components/fieldForm/FieldForm.component";
import { addFieldData } from "../store/actions/fields.actions";

const FieldAddPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 767px");

  const handleGoBack = () => {
    navigate(-1);
  };

  const onSubmit = (values) => {
    dispatch(addFieldData(values));
    handleGoBack();
  };

  return (
    <Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button onClick={handleGoBack}>
          <Icon>arrow_back</Icon>
        </Button>
        <h3>{t("fields-add-page.title")}</h3>
      </div>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ marginBottom: "20px" }}
      >
        {isMobile ? null : (
          <Grid item xs={12} sm={9} md={9}>
            <Typography>
              <span>{t("fields-add-page.subtitle")}</span>
            </Typography>
          </Grid>
        )}
        <Grid item xs={3}></Grid>
      </Grid>

      <div style={{ marginBottom: "80px" }}>
        <FieldFormComponent
          addNew={true}
          onSubmit={onSubmit}
        ></FieldFormComponent>
      </div>

    </Fragment>
  );
};

export default FieldAddPage;
